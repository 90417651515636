/* ContactForm.css */

.contact-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 0 20px;
}

.contact-form {
    background-color: #fff;
    padding: 40px;
    border-radius: 20px;
    box-shadow: 0 5px 15px rgba(0,0,0,0.1);
    width: 100%;
    max-width: 600px;
    margin: 80px auto;
}

.contact-form-header {
    display: flex;
    align-items: center;
    justify-content: center; /* Center the text */
    background-color: #f0f0f0;
    padding: 20px;
    font-weight: 600;
    font-size: 24px;
    border-bottom: 1px solid #e6e6e6;
    border-radius: 40px; /* Apply a larger border-radius for the oval shape */
    margin: 10px auto 20px; /* Adjust margin to shift down */
    width: 80%; /* Centering and controlling width */
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); /* Adding a subtle shadow for depth */
}

.contact-form-field {
    width: 100%;
    margin-top: 20px;
}

.contact-form-field input,
.contact-form-field textarea {
    width: 100%;
    padding: 20px;
    font-size: 1rem;
    border-radius: 15px;
    border: 1px solid #e0e0e0;
    outline: none;
}

.contact-form-field textarea {
    min-height: 150px;
    resize: vertical;
}

.contact-form-button {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.contact-form-button button {
    padding: 10px 20px;
    background-color: #0095f6;
    color: #ffffff;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    font-weight: 600;
    font-size: 1rem;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    text-transform: none;
}

.contact-form-button button:hover {
    background-color: #0077cc;
}

@media (max-width: 768px) {
    .contact-form-container {
        padding: 0;
        margin-top: 30px; /* Increased top margin for spacing */
    }
    .contact-form {
        padding: 20px;
        max-width: 90%;
        border-radius: 20px; /* Ensure rounded edges for smaller screens */
    }
    .contact-form-header {
        padding: 15px;
        font-size: 20px;
        border-radius: 40px; /* Maintain oval shape */
        margin: 10px auto 20px; /* Adjust margin for smaller screens */
        width: 90%; /* Adjust width for smaller screens */
    }
    .contact-form-field input,
    .contact-form-field textarea {
        padding: 15px;
        font-size: 0.9rem;
        border-radius: 15px; /* Ensure rounded edges for inputs and textareas */
    }
    .contact-form-button button {
        padding: 8px 16px;
        font-size: 0.9rem;
        border-radius: 15px; /* Ensure rounded edges for buttons */
    }
}

@media (max-width: 480px) {
    .contact-form-container {
        margin-top: 100px; /* Further increased top margin for smaller screens */
    }
    .contact-form {
        max-width: 100%;
        margin: 20px auto;
        border-radius: 20px; /* Ensure rounded edges for smaller screens */
    }
    .contact-form-header {
        font-size: 18px;
        border-radius: 40px; /* Maintain oval shape */
        margin: 10px auto 20px; /* Adjust margin for smaller screens */
        width: 90%; /* Adjust width for smaller screens */
    }
    .contact-form-field input,
    .contact-form-field textarea {
        font-size: 0.8rem;
        border-radius: 15px; /* Ensure rounded edges for inputs and textareas */
    }
    .contact-form-button button {
        font-size: 0.8rem;
        border-radius: 15px; /* Ensure rounded edges for buttons */
    }
}

@media (min-width: 768px) {
    .contact-form-container {
        padding-left: 170px; /* Added left padding for spacing from sidebar */
    }
}

@media (min-width: 1300px) {
    .contact-form-container {
        margin-top: 50px; /* Adjust this value as needed to move the form down */
    }
}

/* Specific device queries */
@media (max-width: 375px) and (max-height: 667px) {
    /* iPhone SE */
    .contact-form-container {
        margin-top: 150px; /* Adjust this value as needed to move the form down */
    }
}

@media (max-width: 360px) and (max-height: 740px) {
    /* Samsung Galaxy S8+ */
    .contact-form-container {
        margin-top: 150px; /* Adjust this value as needed to move the form down */
    }
}

@media (min-width: 540px) and (min-height: 720px) and (max-width: 720px) {
    /* Surface Duo */
    .contact-form-container {
        margin-top: 100px; /* Adjust this value as needed to move the form down */
    }
}

@media (min-width: 1024px) and (max-width: 1280px) and (min-height: 600px) {
    /* Nest Hub */
    .contact-form-container {
        margin-top: 50px; /* Adjust this value as needed to move the form down */
    }
}

@media (min-width: 1280px) and (max-width: 1920px) and (min-height: 800px) {
    /* Nest Hub Max */
    .contact-form-container {
        margin-top: 50px; /* Adjust this value as needed to move the form down */
    }
}

/* Add media query for 1264px screen width */
@media (min-width: 1264px) and (max-width: 1264px) {
  .contact-form-container {
    margin-left: 100px; /* Adjust this value as needed to shift the container to the right */
  }
  .contact-form {
    margin-left: 100px; /* Adjust this value as needed to shift the form to the right */
  }
}
