/* MessagesPage.css */

.messages-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-left: 250px; /* Adjust this value as necessary */
    font-family: 'Arial', sans-serif;
  }
  
  .main-content {
    display: flex;
    flex: 1;
    height: 100%;
    flex-direction: row;
  }
  
  /* Increased the flex-grow value for .chat-window-section */
  .main-content .chat-window-section {
    flex-grow: 3; /* Increase this value as needed */
    margin-left: 2%;
    width: auto; /* Adjust if necessary */
  }
  
  .spacer {
    flex-grow: 2;  /* This will take up the extra space, effectively pushing the MessagesList and ChatWindow to the left */
  }
  
  .users-list {
    width: 30%;
    height: 100vh;
    border-right: 1px solid #dbdbdb;
    overflow-y: auto;
    padding: 20px;
    margin-right: 2%;
  }
  
  /* Increased specificity for .chat-window-section */
  .main-content .chat-window-section {
    flex-grow: 3; /* Increased flex-grow */
    margin-left: 2%;
    width: auto;
  }
  
  .user {
    display: flex;
    align-items: center;
    padding: 20px 0;
    cursor: pointer;
    border-bottom: 1px solid #dbdbdb;
  }
  
  .user:hover {
    background-color: #f5f5f5;
  }
  
  .user-img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .text-content {
    margin-left: 20px;
  }
  
  .username {
    font-weight: 600;
    margin-bottom: 5px;
  }
  
  .last-message {
    color: #8e8e8e;
  }
  
  .chat-section {
    flex: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fafafa;
  }
  
  .message-bubble {
    max-width: 60%;
    padding: 10px 20px;
    border-radius: 20px;
    margin-bottom: 20px;
    align-self: flex-start;
    background-color: #efefef;
    color: #262626;
  }
  
  .message-bubble.sender {
    align-self: flex-end;
    background-color: #0f93f4;
    color: white;
  }
  
  .input-area {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border-top: 1px solid #dbdbdb;
    background-color: #fff;
  }
  
  .input-area input {
    flex: 1;
    padding: 10px;
    border-radius: 20px;
    border: 1px solid #dbdbdb;
    margin: 0 10px;
  }
  
  .icon {
    font-size: 24px;
    color: #262626;
    opacity: 0.6;
    margin-right: 20px;
  }
  
  .icon:last-child {
    margin-right: 0;
  }
  
  .icon:hover {
    opacity: 1;
  }
  
  .time-stamp {
    margin-left: 10px;
    color: #8e8e8e;
    font-size: 0.8em;
  }
  
  /* Input Area */
  
  .input-area {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border-top: 1px solid #dbdbdb;
    background-color: #fff;
  }
  
  .icon {
    font-size: 24px;
    color: #262626;
    opacity: 0.6;
    margin-right: 20px;
    transition: opacity 0.3s;
  }
  
  .icon:last-child {
    margin-right: 0;
  }
  
  .icon:hover {
    opacity: 1;
  }
  
  .input-area input {
    flex: 1;
    padding: 10px;
    border-radius: 20px;
    border: 1px solid #dbdbdb;
    margin: 0 10px;
    transition: border-color 0.3s;
  }
  
  .input-area input:focus {
    outline: none;
    border-color: #0f93f4;
  }
  
  /* Message Bubble */
  .message-bubble {
    max-width: 60%;
    padding: 10px 20px;
    border-radius: 20px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-self: flex-start;
    background-color: #efefef;
    color: #262626;
  }
  
  .sender {
    align-self: flex-end;
    background-color: #0f93f4;
    color: white;
  }
  
  .receiver {
    background-color: #efefef;
    color: #262626;
  }
  
  /* Chat Profile Pic */
  .chat-profile-pic {
    border: none;
  }
  
  /* Additional styles for username and last message in the chat list */
  .user-name, .last-message {
    margin-left: 10px;
  }
  
  /* User Search Bar */
  .user-search {
    padding: 10px;
    border-bottom: 1px solid #dbdbdb;
  }
  
  .user-search input {
    width: 100%;
    padding: 7px 10px;
    border-radius: 15px;
    border: 1px solid #dbdbdb;
    box-shadow: none;
  }
  
  /* Notification Badge */
  .notification-badge {
    background-color: #0f93f4;
    color: white;
    border-radius: 12px;
    padding: 2px 7px;
    font-size: 12px;
    font-weight: bold;
    margin-left: auto;
  }
  
  /* Mobile Responsive Design */
  @media (max-width: 768px) {
    .messages-container {
      margin-left: 0; /* Adjust to 0 for full-width on small screens */
    }
  
    .main-content {
      flex-direction: column; /* Stack the list and chat window on top of each other */
    }
  
    .users-list {
      width: 100%; /* Make the user list full width */
      display: block; /* Display the user list */
      height: auto; /* Adjust height accordingly */
      border-right: none; /* Remove the right border */
    }
  
    .main-content .chat-window-section,
    .main-content .users-list {
      margin: 0; /* Remove margins for full-width */
    }
  
    .new-chat-button-section {
      order: -1; /* Place the new chat button above the list */
    }
  }
  
  /* Smaller devices */
  @media (max-width: 480px) {
    .header,
    .new-chat-button-section {
      padding: 10px; /* Existing style */
    }
  
    .messages-container {
      margin-top: 60px; /* Adjust this value as needed to increase space */
    }
  
    /* Adjust button sizes and font sizes as needed */
  }
  
  .new-chat-button-section {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }
  
  /* Device-specific media queries */
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    /* iPad Mini */
    .messages-container {
      margin-top: 180px; /* Adjust this value as needed */
    }
  }
  
  @media only screen and (min-device-width: 820px) and (max-device-width: 1180px) {
    /* iPad Air */
    .messages-container {
      margin-top: 200px; /* Adjust this value as needed */
    }
  }
  
  @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) {
    /* iPad Pro */
    .messages-container {
      margin-top: 220px; /* Adjust this value as needed */
    }
  }
  
  @media only screen and (min-device-width: 912px) and (max-device-width: 1368px) {
    /* Surface Pro 7 */
    .messages-container {
      margin-top: 200px; /* Adjust this value as needed */
    }
  }
  
  @media (min-width: 540px) and (min-height: 720px) and (max-width: 720px) {
    /* Surface Duo */
    .messages-container {
      margin-top: 100px; /* Adjust this value as needed */
    }
  }
  
  @media (min-width: 1920px) and (max-width: 2560px) {
    /* Asus Zenbook Fold */
    .messages-container {
      margin-top: 250px; /* Adjust this value as needed */
    }
  }
  
  @media (min-width: 1024px) and (max-width: 600px) {
    /* Nest Hub */
    .messages-container {
      margin-top: 160px; /* Adjust this value as needed */
    }
  }
  
  @media (min-width: 1280px) and (max-width: 800px) {
    /* Nest Hub Max */
    .messages-container {
      margin-top: 180px; /* Adjust this value as needed */
    }
  }
  
  @media (min-width: 1264px) {
    /* 1264px and higher */
    .messages-container {
      margin-top: 100px; /* Adjust this value as needed */
    }
  }
  