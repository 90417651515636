/* ExplorePage.css */

.explore-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px 20px 20px;
    padding: 20px;
}

.explore-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    width: 100%;
    max-width: 1200px; /* Ensure the grid does not exceed a certain width */
}

.explore-item {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-top: 100%; /* Maintains aspect ratio */
    background-color: #f5f5f5;
}

.explore-item img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media (max-width: 768px) {
    .explore-container {
        padding-left: 20px; /* Keeps reduced left padding for smaller screens */
        padding-right: 20px; /* Ensures content does not touch the edges */
        padding-top: 150px; /* Increased top padding to create more space between the Navbar and the grid */
    }
    .explore-grid {
        grid-template-columns: repeat(2, 1fr); /* Optionally adjust for fewer columns on very small screens */
    }
}

@media (min-width: 768px) {
    .explore-container {
        padding-left: 80px; /* Increase left padding to align with the Sidebar */
    }
    .explore-grid {
        gap: 20px; /* Increase gap size if more spacing is desired */
    }
}

/* iPad Air */
@media only screen and (min-device-width: 820px) and (max-device-width: 1180px) {
    .explore-container {
        padding-left: 220px; /* Adjust the value to ensure the container is not overlapped by the Sidebar */
        max-width: calc(100% - 220px); /* Adjust the max-width to fit within the available space */
        margin-top: 100px; /* Move the container down */
    }

    .explore-grid {
        grid-template-columns: repeat(auto-fill, minmax(350px, 1fr)); /* Increase the minmax value to make images larger */
    }

    .explore-item img {
        width: 100%; /* Ensure images take the full width of their container */
        height: auto; /* Maintain aspect ratio */
    }
}

/* Adjustments for screens with 1264px width */
@media (min-width: 1264px) and (max-width: 1264px) {
    .explore-container {
        padding-left: 260px; /* Move the container and contents slightly to the left */
    }
}
