/* InfoPage.css */

.info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 800px;
    margin: 80px auto;
    padding: 20px;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    margin-left: 180px;
    border: 2px solid #000;
}

.info-container section {
    margin-bottom: 20px;
}

.info-container h1, 
.info-container h2 {
    color: #333;
    margin-bottom: 10px;
}

.info-container p,
.info-container li {
    color: #666;
    line-height: 1.6;
    margin-bottom: 10px;
}

.welcome-section h1 {
    color: #0275d8;
}

.terms-of-use, 
.disclaimer-section, 
.claims-section, 
.miscellaneous-section {
    background: #fff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.info-container, 
.info-container h1, 
.info-container h2, 
.info-container h3, 
.info-container p, 
.info-container ul, 
.info-container li {
    text-align: left;
}

.info-container h1 {
    font-size: 2rem;
}

.info-container h2 {
    font-size: 1.75rem;
}

.info-container p,
.info-container li {
    font-size: 1rem;
}

/* Adjustments for medium screens (less than 992px) */
@media screen and (max-width: 992px) {
    .info-container {
        margin: 180px 20px 20px;
    }
}

/* Adjustments for small screens (less than 768px) */
@media screen and (max-width: 768px) {
    .info-container {
        margin: 180px 20px 20px;
        max-width: calc(100% - 40px);
    }
}

/* Further adjustments for extra-small screens (less than 576px) */
@media screen and (max-width: 576px) {
    .info-container {
        margin: 180px 20px 20px;
    }

    .info-container h1 {
        font-size: 1.5rem;
    }

    .info-container h2 {
        font-size: 1.3rem;
    }

    .info-container p,
    .info-container li {
        font-size: 0.9rem;
    }
}

/* Adjustments for large screens (greater than 1300px) */
@media screen and (min-width: 1300px) {
    .info-container {
        margin-top: 120px;
    }
}

/* Adjustments for Nest Hub (1024x600) */
@media screen and (device-width: 1024px) and (device-height: 600px) {
    .info-container {
        margin-top: 120px;
    }
}

/* Adjustments for Nest Hub Max (1280x800) */
@media screen and (device-width: 1280px) and (device-height: 800px) {
    .info-container {
        margin-top: 120px;
    }
}

/* Adjustments for iPad Pro (12.9-inch) */
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
    .info-container {
        margin-top: 120px;
    }
}

/* Adjustments for iPad Air (10.9-inch) */
@media only screen and (min-device-width: 820px) and (max-device-width: 1180px) and (-webkit-min-device-pixel-ratio: 2) {
    .info-container {
        margin-left: 200px;
        max-width: 500px;
    }
}

/* Adjustments for Asus Zenbook Fold (853x1280) */
@media screen and (min-width: 853px) and (max-width: 1280px) {
    .info-container {
        margin-left: 200px;
        max-width: 600px;
    }
}

/* Adjustments for 1264px width screens */
@media screen and (max-width: 1264px) and (min-width: 1264px) {
    .info-container {
        margin-top: 150px; /* Adjust this value as needed */
    }
}

/* Adjustments for 1281px width screens */
@media screen and (max-width: 1281px) and (min-width: 1281px) {
    .info-container {
        margin-top: 150px; /* Adjust this value as needed */
    }
}
