/* LikesPage.css */

.likes-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 80px 20px 20px;
  padding: 20px;
  padding-left: 180px;
}

.page-heading {
  text-align: center;
}

@media screen and (max-width: 767px) {
  .likes-page {
    align-items: flex-start;
    margin: 160px 10px 10px;
    padding: 20px 10px;
    padding-left: 10px;
  }

  .page-heading {
    text-align: left;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .likes-page {
    margin-top: 160px;
    align-items: flex-start;
    margin-left: 20px;
    margin-right: 20px;
    max-width: calc(100% - 40px);
  }

  .page-heading {
    text-align: left;
    margin-left: 0;
  }

  .post-container {
    align-self: flex-start;
    width: 100%;
    padding: 0;
    margin-left: 0;
  }
}

@media screen and (min-width: 768px) {
  .likes-page {
    margin-left: auto;
    margin-right: auto;
    max-width: 768px;
  }
}

@media only screen and (min-width: 992px) {
  .likes-page {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 991px) {
  .likes-page {
    margin-top: 80px;
  }
}

/* Add media query for iPad Mini */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait),
       only screen and (min-device-width: 1024px) and (max-device-width: 768px) and (orientation: landscape) {
  .likes-page {
    margin-top: 120px;
    align-items: flex-start;
    margin-left: 20px;
    margin-right: 20px;
    max-width: calc(100% - 40px);
    padding-left: 20px;
  }

  .page-heading {
    text-align: left;
    margin-left: 0;
  }

  .post-container {
    width: 100%;
    padding: 0 10px;
    margin-left: 0;
  }
}

/* Add media query for iPad Air */
@media only screen 
  and (min-device-width: 820px) 
  and (max-device-width: 1180px) 
  and (-webkit-min-device-pixel-ratio: 1.5) {
  .likes-page {
    padding-left: 200px; /* Increase left padding to accommodate Sidebar */
    margin-left: 0;
  }

  .post-container {
    width: calc(100% - 220px); /* Reduce width by subtracting Sidebar width and some extra space */
    max-width: 700px;
    padding: 0 20px;
    margin-left: auto;
    margin-right: 0;
  }
}

/* Add media query for 1264px screen width */
@media (min-width: 1264px) and (max-width: 1264px) {
  .likes-page {
    margin-top: 100px; /* Adjust this value as needed to move the heading down slightly */
  }
}

