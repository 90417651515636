/* ProfilePage.css */

body {
    background-color: #F9F9F9; /* Soft gray background */
  }
  
  .profile-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centers children horizontally */
    padding: 20px;
    box-sizing: border-box;
  }
  
  .profile-header {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items horizontally */
    width: 100%; /* Full width to center within the parent container */
    margin-top: 80px; /* Increased spacing */
  }
  
  .profile-picture-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .profile-picture {
    font-size: 150px;
    color: #757575; /* Grey color for the Material UI icon */
  }
  
  .user-info {
    text-align: center;
  }
  
  .user-name {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0.5rem 0;
  }
  
  .post-count {
    color: #777;
  }
  
  .explore-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Set three images per row */
    gap: 20px;
    width: 100%; /* Make grid take full width of the available space */
    margin: auto;
    margin-top: 40px;
  }
  
  .explore-item img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  @media screen and (max-width: 768px) {
    .profile-container {
      padding: 20px 10px; /* Adjusted padding for smaller screens */
    }
  
    .profile-header {
      width: 100%;
      align-items: center;
      justify-content: center;
      margin-top: 80px; /* Add more space between the Navbar and profile header */
    }
  
    .profile-picture-container {
      align-items: center; /* Center align to match the grid */
      margin-top: 8px; /* Adjusted top margin to create more space */
    }
  
    .profile-picture {
      font-size: 42px; /* Reduced size for more vertical space */
      margin-top: 30px; /* Add space above the profile picture */
    }
  
    .user-info {
      text-align: center;
      padding-top: 8px; /* Added padding to create more space */
    }
  
    .user-name {
      font-size: 1.2rem; /* Smaller font size for smaller screens */
    }
  
    .post-count {
      font-size: 1rem; /* Smaller font size for smaller screens */
      padding-top: 4px; /* Added padding to create more space */
    }
  
    .explore-grid {
      grid-template-columns: repeat(2, 1fr); /* Adjust grid for smaller screens */
      gap: 10px; /* Adjust gap for smaller screens */
      margin-top: 20px; /* Adjust margin-top for smaller screens */
    }
  }
  
/* iPad Air */
@media only screen and (min-device-width: 820px) and (max-device-width: 1180px) {
    .profile-container {
       width: 70%; /* Set the overall width of the profile container to 70% */
       padding: 10px; /* Adjust padding for a smaller container */
       margin-top: 20px;
       margin-left: 30px; /* Increase left margin to shift container further to the right */
     }
   
    .profile-header {
       width: 100%;
       margin-top: 40px; /* Move the profile header down slightly */
     }
   
    .profile-picture-container {
       align-items: center;
       margin-top: 20px; /* Adjust top margin */
     }
   
    .profile-picture {
       font-size: 80px; /* Smaller icon size for iPad Air */
     }
   
    .user-info {
       text-align: center;
       padding-top: 10px; /* Add padding to create space */
     }
   
    .user-name {
       font-size: 1rem; /* Smaller font size for iPad Air */
     }
   
    .post-count {
       font-size: 0.9rem; /* Smaller font size for iPad Air */
       padding-top: 5px; /* Add padding to create space */
     }
   
    .explore-grid {
       grid-template-columns: repeat(2, 1fr); /* Adjust grid for iPad Air */
       gap: 10px; /* Adjust gap for smaller screens */
       margin-top: 20px; /* Adjust margin-top for smaller screens */
     }
   }   
  
  /* Asus Zenbook Fold */
  @media only screen and (min-device-width: 1920px) and (max-device-width: 2560px) {
    .profile-container {
      max-width: 800px; /* Reduce the overall width of the profile container */
      margin-left: 50px; /* Shift container to the right */
    }
  
    .profile-header {
      margin-left: 50px; /* Shift container to the right */
    }
  
    .profile-picture-container {
      margin-left: 50px; /* Shift container to the right */
    }
  
    .profile-picture {
      font-size: 140px; /* Adjust icon size for Asus Zenbook Fold */
    }
  }
  
  /* iPad Pro */
  @media only screen and (min-device-width: 1025px) and (max-device-width: 1366px) {
    .profile-container {
      max-width: 800px; /* Adjust width for iPad Pro */
      margin-left: 30px; /* Shift container to the right */
    }
  
    .profile-header {
      margin-left: 30px; /* Shift container to the right */
    }
  
    .profile-picture-container {
      margin-left: 30px; /* Shift container to the right */
    }
  
    .profile-picture {
      font-size: 100px; /* Adjust icon size for iPad Pro */
    }
  }
  
  /* Surface Pro 7 */
  @media only screen and (min-device-width: 1368px) and (max-device-width: 1920px) {
    .profile-container {
      max-width: 900px; /* Adjust width for Surface Pro 7 */
      margin-left: 40px; /* Shift container to the right */
    }
  
    .profile-header {
      margin-left: 40px; /* Shift container to the right */
    }
  
    .profile-picture-container {
      margin-left: 40px; /* Shift container to the right */
    }
  
    .profile-picture {
      font-size: 120px; /* Adjust icon size for Surface Pro 7 */
    }
  }
  
  /* Nest Hub */
  @media only screen and (min-device-width: 600px) and (max-device-width: 1024px) {
    .profile-container {
      max-width: 700px; /* Adjust width for Nest Hub */
      margin-left: 20px; /* Shift container to the right */
    }
  
    .profile-header {
      margin-left: 20px; /* Shift container to the right */
    }
  
    .profile-picture-container {
      margin-left: 20px; /* Shift container to the right */
    }
  
    .profile-picture {
      font-size: 90px; /* Adjust icon size for Nest Hub */
    }
  }
  