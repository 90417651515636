/* PostForm.css */

/* Basic Reset for Form Elements */
* {
    box-sizing: border-box;
    outline: none;
}

/* Smooth transition for any hover or focus effects */
*,
*:hover,
*:focus {
    transition: all 0.3s ease;
}

/* Main Form Container Styling */
.form-container {
    position: relative;
    max-width: calc(100% - 180px);
    margin-left: 180px;
    margin-top: 70px;
    margin-bottom: 70px;
    padding: 40px;
    background-color: #1f1f1f;
    border-radius: 20px;
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    animation: fadeIn 0.7s forwards;
}

/* PostVerification specific styling for 1264px width */
@media (min-width: 1264px) and (max-width: 1264px) {
    .form-container {
        margin-top: 150px; /* Adjust the margin-top value as needed */
    }
}

.close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    color: #fff;
    cursor: pointer;
    z-index: 10;
}

/* Form Title Styling */
.form-title {
    font-size: 24px;
    margin-bottom: 25px;
    color: #ffffff;
    letter-spacing: -1px;
    border-bottom: 2px solid #333;
    padding-bottom: 15px;
    text-align: left; 
}

/* General Group Styling */
.form-group {
    margin: 40px 0;
}

/* Labels inside the Form Group */
.form-group label {
    display: block;
    margin-bottom: 15px;
    font-size: 18px;
    color: #dddddd;
    letter-spacing: 0.5px;
    text-align: left;
}

/* Input and Textarea Styling */
.form-input,
.form-textarea,
.form-options input[type="checkbox"] {
    width: 100%;
    padding: 15px 20px;
    background-color: #292929;
    border-radius: 10px;
    border: none;
    font-size: 16px;
    color: #fff;
}

/* Placeholder Color */
.form-input::placeholder,
.form-textarea::placeholder {
    color: #777777;
}

/* Focus Effects */
.form-input:focus,
.form-textarea:focus,
.form-options input[type="checkbox"]:focus {
    background-color: #3a3a3a;
    box-shadow: 0 0 5px rgba(28,169,255,0.5);
}

/* Styling the options for checkboxes */
.form-options {
    display: flex;
    flex-wrap: wrap;
    gap: 10px 20px;
    align-items: center;
}

/* Ensure the label is properly aligned with the checkbox */
.form-options label {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #ccc;
}

/* Checkbox input styling */
.form-options input[type="checkbox"] {
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin: 0;
}

/* Checkbox with label styling */
.form-options div {
    display: flex;
    align-items: center;
}

/* Button Styling */
.form-button {
    padding: 15px 30px;
    background-color: #1ca9ff;
    color: #ffffff;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    display: block;
    margin: 20px auto;
}

/* Button Hover Effect */
.form-button:hover {
    background-color: #1691cc;
}

/* Button Active Effect */
.form-button:active {
    transform: scale(0.98);
}

/* Fade-in Animation */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Applying fade-in animation */
.form-container {
    animation: fadeIn 0.7s forwards;
}

.upload__image-wrapper {
    margin-bottom: 20px;
}

.image-item {
    display: inline-block;
    position: relative;
    margin-right: 10px;
}

.image-item__btn-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    display: none;
}

.image-item:hover .image-item__btn-wrapper {
    display: block;
}

/* Drag & Drop Zone Styling */
.drag-drop-zone {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px dashed #4CAF50;
    padding: 20px;
    text-align: center;
    margin-bottom: 20px;
    border-radius: 10px;
    background-color: #292929;
    color: #fff;
}

/* Upload Icon Styling */
.upload-icon,
.images-icon {
    color: #4CAF50;
    margin-bottom: 10px;
    margin-top: 10px;
}

.uploaded-image-preview {
    max-width: 100%;
    max-height: 150px;
    height: auto;
    display: block;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    object-fit: contain;
}

/* Resetting any browser default styles that might affect alignment */
input[type='checkbox'] {
    -webkit-appearance: checkbox;
    appearance: checkbox;
}

/* Add this to the bottom of your PostForm.css file */

/* Horizontal alignment of checkboxes and labels */
.form-options {
    display: flex;
    flex-wrap: wrap;
    gap: 10px 20px;
    align-items: center;
    justify-content: flex-start;
}

.form-options div {
    display: flex;
    align-items: center;
    gap: 10px;
}

.form-options input[type="checkbox"],
.form-options label {
    margin: 0;
    padding: 0;
}

.form-container .form-options {
    display: flex;
    flex-wrap: wrap;
    gap: 10px 20px;
    align-items: center;
    justify-content: flex-start;
}

.form-container .form-options div {
    display: flex;
    align-items: center;
    gap: 10px;
}

.form-container .form-options input[type="checkbox"] {
    margin-right: 5px;
}

.form-options > div {
    display: inline-flex;
    margin-right: 10px;
    align-items: center;
}

@media (max-width: 768px) {
    .form-container {
        max-width: 100%;
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 180px;
        padding: 20px;
    }
}

@media (min-width: 768px) {
    .form-container {
        max-width: 600px;
        margin-left: 180px;
    }
}

.password-verification {
    max-width: calc(100% - 180px);
    margin-left: 250px;
    margin-top: 100px;
    margin-bottom: 70px;
    padding: 40px;
    background-color: #1f1f1f;
    border-radius: 20px;
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.7s forwards;
}

.password-verification-title {
    font-size: 34px;
    color: #ffffff;
    margin-bottom: 25px;
    border-bottom: 2px solid #333;
    padding-bottom: 15px;
    text-align: center;
}

.password-verification-form-group {
    margin: 40px 0;
    text-align: center;
}

.password-verification-form-group label {
    display: block;
    margin-bottom: 15px;
    font-size: 18px;
    color: #dddddd;
}

.password-verification-input {
    width: 100%;
    padding: 15px 20px;
    background-color: #292929;
    border-radius: 10px;
    border: none;
    font-size: 16px;
    color: #fff;
    margin-bottom: 20px;
}

.password-verification-input:focus {
    background-color: #3a3a3a;
    box-shadow: 0 0 5px rgba(28,169,255,0.5);
}

.password-verification-button {
    padding: 15px 30px;
    background-color: #1ca9ff;
    color: #ffffff;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    display: block;
    margin: auto;
}

.password-verification-button:hover {
    background-color: #1691cc;
}

.password-verification-button:active {
    transform: scale(0.98);
}

.password-error {
    color: #ff6b6b;
    text-align: center;
    margin-top: 10px;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
}

input[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
}

.form-input[type='number']::placeholder {
    color: #777777;
}

.image-preview-container {
    position: relative;
    display: inline-block;
}

.uploaded-image-preview {
    max-width: 100%;
    max-height: 150px;
    height: auto;
    display: block;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    object-fit: contain;
}

.remove-image-icon {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    background-color: #ffffff;
    color: #ff0000;
    border-radius: 50%;
    padding: 2px 5px;
    font-size: 14px;
    font-weight: bold;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    z-index: 10;
}

.suggestion-link {
    display: flex;
    align-items: center;
    color: #007bff;
    font-size: 16px;
    text-decoration: none;
}

.suggestion-link svg {
    margin-left: 5px;
    font-size: 20px;
}

@media (min-width: 1346px) {
    .form-container {
        margin-top: 150px;
    }
}
