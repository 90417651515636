/* App.css */

.App {
  text-align: center;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App-header {
  color: #333;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
}

.main-content {
  flex: 1;
  padding-top: 90px; /* Adjusted for additional Navbar spacing */
}

.posts {
  max-width: 80%;
  padding-top: 40px; /* Adjusted for appropriate spacing */
}

.post-container {
  width: 100%;
  padding-top: 40px; /* Adjusted for appropriate spacing */
}

.Sidebar {
  width: 350px;
  height: calc(100vh - 90px); /* Adjusted for navbar height */
  overflow-y: auto;
  position: fixed;
  right: 0;
  top: 90px; /* Adjusted for navbar height */
  bottom: 0;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .posts, .Sidebar {
    margin-left: 0;
    max-width: 100%;
    width: 100%;
  }

  .Sidebar {
    width: 80%; /* Adjust as needed for mobile view */
    height: auto; /* Make sidebar height automatic */
    position: static; /* Remove fixed positioning */
    top: auto;
  }

  .App {
    padding-bottom: 2rem; /* Increase spacing at the bottom */
  }
}

@media (min-width: 768px) {
  .Sidebar {
    display: block; /* Ensure sidebar is visible on larger screens */
    width: 230px; /* Adjust to match the width in Sidebar.js */
    height: calc(100vh - 90px); /* Adjusted for navbar height */
    position: fixed; /* Fix position to the side */
    right: 0; /* Align to the right side */
    top: 90px; /* Adjusted for navbar height */
  }
}

@media (max-width: 480px) {
  .App-header {
    padding: 10px; /* Adjust header padding on smaller screens */
  }

  .app-footer {
    display: flex; /* Ensure footer is displayed */
    justify-content: center; /* Center footer content */
    align-items: center; /* Vertically center footer content */
    background-color: #f4f7f9; /* Updated to light background color */
    color: #333; /* Footer text color */
    padding: 10px 0; /* Footer padding */
    width: 100%; /* Full width */
    text-align: center; /* Center text */
    position: fixed; /* Fix footer to the bottom */
    bottom: 0; /* Align to bottom */
    left: 0; /* Align to left */
    z-index: 1000; /* Ensure footer is above other content */
  }

  .posts {
    padding-top: 60px; /* Adjusted for appropriate spacing */
  }
}

@media (min-width: 1264px) {
  #navbar .navbar-item {
    padding: 5px 10px !important; /* Reduced padding */
    font-size: 1.1rem !important; /* Reduced font size */
    border-radius: 12px !important;
  }

  #navbar .navbar-icon {
    width: 16px !important; /* Reduced icon size */
    height: 16px !important; /* Reduced icon size */
  }
}

@media (min-width: 1300px) {
  #navbar .navbar-item {
    padding: 5px 10px !important; /* Reduced padding */
    font-size: 1.1rem !important; /* Reduced font size */
    border-radius: 12px !important;
  }

  #navbar .navbar-icon {
    width: 16px !important; /* Reduced icon size */
    height: 16px !important; /* Reduced icon size */
  }
}