/* Post.css */

#post {
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    background-color: #FFFFFF;
    border-radius: 8px;
    margin: 5px 0;
    padding: 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.1);
    align-items: center;
    width: 100%;
    max-width: 2000px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    position: relative; /* Positions the post relative to its normal position */
}

#post .post-image {
    width: 100%; 
    max-width: 120px;     /* Maximum width of the image */
    height: auto;         /* Adjusted to auto to maintain the original aspect ratio */
    max-height: 120px;    /* Maximum height of the image */
    margin-right: 15px;
    border-radius: 6px;
    object-fit: cover;  /* This will ensure that the entire image is visible */
}

#post .post-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: flex-start; /* Aligns all children at the start horizontally */
}

#post .post-title {
    font-size: 20px;
    font-weight: bold;
    color: #1A0DAB;
    margin: 10px 0 8px 0; /* No negative margin */
    align-self: flex-start; /* Aligns the title at the start horizontally */
}

#post .post-metadata {
    font-size: 12px;
    color: #888888;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column; /* This ensures the author and timestamp are on separate lines */
    align-items: flex-start; /* Aligns the metadata at the start horizontally */
    gap: 4px; /* Gap for space between elements */
}

#post .post-author {
    color: #1A0DAB;
    text-decoration: underline; /* Underlined usernames, Reddit-style */
    cursor: pointer;
}

#post .post-timestamp {
    color: #888888;
}

#post .post-footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

#post .post-comments, 
#post .post-share {
    font-size: 14px;
    color: #717171;
    cursor: pointer;
}

#post .profile-pic {
    display: none; /* Hide the image */
}

#post .post-footer i {
    margin-right: 8px;
    font-size: 14px;
}

/* Media Query for smaller screens */
@media (max-width: 768px) {
    #post {
        margin: 5px;
        padding: 8px;
    }

    #post .post-image {
        margin-bottom: 12px;
        margin-right: 0;
    }

    #post .post-content {
        align-items: center;
        text-align: center;
    }
}

#post .post-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Aligns all children at the start horizontally */
    gap: 4px;
}

#post .post-price,
#post .post-conditions,
#post .post-location {
    font-size: 14px;
    color: #888888;
    margin-bottom: 8px;
}

#post .post-price,
#post .post-conditions {
    max-width: 100%; /* Adjust the value to match the width of your price text */
    word-wrap: break-word;
}

#post .post-share {
    font-size: 14px;
    color: #717171;
    cursor: pointer;
    position: absolute; /* Positions the share button relative to the nearest positioned ancestor */
    top: 10px; /* Moves the share button down from the top of the container */
    right: 0; /* Positions the share button at the right of the container */
}

#post .post-conditions {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

#post .post-conditions .condition {
    margin-right: 4px;
}

#post .post-conditions .condition.wrap {
    flex: 1 0 100%; /* Forces all condition elements except the first one to take up 100% of the width */
}

#post .post-details {
    text-align: left;
    padding-left: 0; /* Removes left padding */
    margin-left: 0; /* Removes left margin */
}

/* Styles specific to PostPage */

#post.postPage {
    display: flex;
    flex-direction: column;
    align-items: left;
    width: 100%;
    max-width: 720px; /* Example width, adjust as needed */
    margin-left: 20px; /* Example margin, adjust as needed */
    margin-right: auto;
    margin: 0 auto;
    padding: 16px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
    text-align: left;
}

#post.postPage .post-title {
    font-size: 20px;
    font-weight: bold;
    color: #1A0DAB;
    margin: 10px 0 8px 0;
}

#post.postPage .post-metadata {
    font-size: 12px;
    color: #888888;
    margin-bottom: 16px;
}

#post.postPage .post-author {
    color: #1A0DAB;
    text-decoration: underline;
}

#post.postPage .post-footer .post-details {
    font-size: 36px;  
}

#post.postPage .post-comments,
#post.postPage .post-share,
#post.postPage .post-footer i {
    font-size: 14px;
    color: #717171;
}

#post.postPage .post-price,
#post.postPage .post-conditions,
#post.postPage .post-location {
    font-size: 14px;
    color: #888888;
    margin-bottom: 8px;
}

#post.postPage .post-title {
    margin: 30px 0 24px 0;
}

/* Adjusting the position of the share button for visibility */
#post.postPage .post-share {
    top: 10px;
    right: 0;
}

.likes-section .liked {
    color: red;
  }

.heart-icon {
    color: #bbbbbb;  /* Default gray color for the unfilled heart */
    cursor: pointer; /* Makes it obvious to users that the icon is clickable */
    transition: color 0.2s;  /* Smooth transition effect */
}

.heart-icon.liked {
    color: #e0245e;  /* Twitter's like color */
}

.likes-section .liked {
    color: red;
  }

@keyframes pop {
  0% { transform: scale(1); }
  50% { transform: scale(1.3); }
  100% { transform: scale(1); }
}

.heart-icon {
  font-size: 24px; /* Adjust size if needed */
  color: #bbbbbb; /* Default gray color, like in Twitter when not liked */
  cursor: pointer;
  transition: color 0.3s ease;
}

.heart-icon.liked {
  color: #e0245e; /* Twitter's like color */
  animation: pop 0.3s ease;
}

.heart-icon {
    color: black; /* Default color for the unfilled heart */
}

.heart-icon.liked {
    color: #ed4956; /* Instagram-like red for the filled heart */
}

/* This targets the SVG path of the heart icon, reducing the border thickness */
.heart-icon svg path {
    strokeWidth: 0.5 !important; /* Adjust as needed. The !important is to override any inline styles. */
}

/* Styles specific to posts in SearchResultsPage */

.SearchResultsPage .post { /* Using class selector instead of ID */
    display: flex;         /* Enables flexbox */
    width: 100%;           /* Ensures the post takes the full width of the container */
    max-width: 600px;      /* Adjust max width to control the post size in search results */
    margin: 10px auto;     /* Centers the post with a margin above and below */
    flex-direction: row;   /* Align items in a row for a compact look */
}

.SearchResultsPage .post .post-image {
    width: 100px;
    height: 100px;
    object-fit: cover;     /* Ensures the image covers the defined space without distortion */
}

.SearchResultsPage .post .post-content {
    flex-grow: 1;          /* Allows the content to fill the available space */
    padding-left: 10px;    /* Spacing between image and text content */
}

.SearchResultsPage .post .post-title {
    font-size: 18px;       /* Slightly smaller font size for search result layout */
}

.SearchResultsPage .post .post-metadata,
.SearchResultsPage .post .post-footer {
    display: none;         /* Hides elements not needed in search results view */
}

/* Optional: Adds a hover effect to posts in search results */
.SearchResultsPage .post:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    transform: translateY(-2px); /* Slight lift effect */
    transition: box-shadow 0.3s ease, transform 0.3s ease;
}

/* Styles specific to Report Form */

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10; /* Ensure it's above other content */
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    z-index: 11; /* Above the backdrop */
  }
  
  .report-form-modal {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 90%; /* Use a percentage-based width */
    max-width: 500px; /* Maximum width can stay the same */
    margin: auto;
    padding: 20px;
    position: relative; /* Use relative for positioning */
    overflow-y: auto; /* Add scroll for smaller devices */
    max-height: 90vh; /* Maximum height to ensure it doesn't go off-screen */
  }

/* Add a media query for smaller screens to adjust the modal */
@media (max-width: 768px) {
  .report-form-modal {
    width: 95%; /* Let it take a bit more space on smaller screens */
    padding: 10px; /* Reduce padding on smaller screens */
    max-height: 85vh; /* Adjust max height for mobile */
  }
}
  
  .report-form-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .close-modal-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
  
  h2 {
    flex-grow: 1; /* Added to make the header text grow */
    text-align: center; /* Added to center the text */
    font-size: 18px;
    color: #333;
    font-weight: normal;
    margin: 0;
  }
  
  .report-form {
    font-family: 'Arial', sans-serif;
  }
  
  .report-reasons label {
    display: block;
    margin: 10px 0;
  }
  
  .report-reasons input[type="radio"] {
    margin-right: 10px;
  }
  
  .other-details-textarea {
    width: 100%;
    height: 100px;
    margin-top: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    font-family: 'Arial', sans-serif;
  }
  
  .report-button {
    background-color: #c4302b;
    color: #fff;
    border: none;
    padding: 10px 20px;
    text-transform: uppercase;
    font-weight: bold;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
    display: block;
    width: 100%;
  }
  
  /* Add additional styling for hover states, focus states, etc. */
  .report-button:hover {
    background-color: #a5281c;
  }
  
  /* If using a modal overlay */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .report-confirmation h2 {
    text-align: center;
    color: #333;
  }
  
  .report-confirmation p {
    text-align: center;
    color: #666;
  }
  
  .report-confirmation button {
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
  }

/* Styles for the container of the share icon */
.share-icon-container {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    z-index: 10; /* Ensure it's above other elements */
}

/* Styles for the share icon itself */
.share-icon {
    font-size: 24px; /* Adjust size as needed */
    color: #666; /* Adjust color as needed */
    transition: color 0.3s ease;
}

/* Change color on hover for better user interaction */
.share-icon:hover {
    color: #333; /* Darker shade on hover */
}

/* Styles for the copy success message */
.copy-success-message {
    margin-top: 5px;
    font-size: 12px;
    color: green; /* Change as per your theme */
    text-align: center;
}

/* Styles for Likes Count */

.post .MuiCardActions-root {
    display: flex;
    align-items: center;
  }
  
  .votes-count {
    margin-left: 8px; /* Adjust as needed for alignment */
  }


/* Specific styles for posts in search results, if any adjustments are needed */

.search-results-container {
  margin-top: 100px;
  margin-left: 170px; /* Adjusted margin-left */
}

.search-results-container .post {
  max-width: 600px; /* Set a max-width to control the size of posts */
  margin-left: 0; /* Reset any specific left margin if needed */
  margin-right: auto; /* Keep the posts centered if there's extra space */
}

@media (max-width: 768px) {
  .search-results-container {
    margin-top: 170px; /* A smaller top margin for smaller screens, if the Navbar is shorter */
    margin-left: 0; /* You may want to remove or decrease the left margin on smaller screens */
  }
}

