/* Sidebar.css */

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 200px;
  height: 100%;
  background: #ffffff;
  color: #333;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: auto;
  transition: transform 0.35s ease-in-out;
  z-index: 999;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  transform: translateX(-100%);
}

.sidebar.open {
  transform: translateX(0);
}

.profile-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.profile-pic {
  width: 120px; /* Increased width */
  height: 120px; /* Increased height */
  margin-bottom: 8px;
  border: 2px solid #e6e6e6;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.profile-pic:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.profile-name-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.profile-name {
  font-size: 20px;
  font-weight: 700;
  color: #333;
  line-height: 1.2;
  font-family: 'Roboto', sans-serif;
  text-align: center;
}

.sidebar-menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sidebar-item {
  margin-bottom: 12px;
  padding: 6px 8px;
  transition: background-color 0.3s ease, color 0.3s ease;
  border-radius: 6px;
}

.sidebar-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.sidebar-icon {
  font-size: 20px;
  color: #000;
}

.sidebar-item a {
  display: flex;
  align-items: center;
  width: 100%;
  color: #333;
  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
}

.sidebar-link-content {
  display: flex;
  align-items: center;
}

.sidebar-text {
  margin-left: 8px;
  font-weight: bold;
  vertical-align: middle;
}

.sidebar-item:hover, .sidebar-item.active {
  background-color: #0095f6;
  color: #fff;
  transform: translateX(3px);
}

.sidebar-item.active {
  transform: scale(1.03);
}

.sidebar-close-button {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1000;
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 768px) {
  .sidebar {
    width: 80%;
  }
}

@media (max-width: 480px) {
  .sidebar {
    width: 100%;
  }

  .sidebar-close-button {
    right: 15px;
    top: 15px;
    justify-content: flex-end;
  }
}