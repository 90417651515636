/* ChatComponent.css */

.chat-component-container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  margin-top: 100px; /* Increased top margin for spacing */
  padding: 0;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border: 1px solid #dbdbdb;
  background-color: #fff;
}
 
/* Media query for screens larger than 768px */
@media (min-width: 768px) {
  .chat-component-container {
    /* Revert to previous max-width values */
    max-width: 80%;
    /* Center the container */
    margin-left: 230px;
    margin-right: auto;
  }
}

/* Media query for screens larger than 992px */
@media (min-width: 992px) {
  .chat-component-container {
    /* Revert to previous max-width values */
    max-width: 60%;
    /* Center the container */
    margin-left: 230px;
    margin-right: auto;
  }
}

/* Media query for small screens, typically mobile devices */
@media (max-width: 767px) {
  .chat-component-container {
    margin-top: 140px; /* Maintain the top margin for the Navbar */
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
  }

  .chat-header {
    height: 60px; /* Fixed height for the header */
  }

  .chat-input-area {
    height: 60px; /* Fixed height for the input area */
  }

  .chat-container {
    overflow-y: auto; /* Allow scrolling within the message area */
    /* Slightly reduce the max-height by an additional amount to ensure visibility */
    max-height: calc(100vh - 140px - 60px - 60px - 80px); /* Subtract the header, input area, top margin, and a little extra space */
  }
}
 
 .chat-header {
  padding: 16px 20px;
  border-bottom: 1px solid #dbdbdb;
  background-color: #fafafa;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between; /* Adjusted for better spacing */
}

.chat-header .MuiTypography-h6 {
  white-space: nowrap; /* Ensure the title doesn't wrap */
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 8px; /* Add some margin if the button gets too close */
}

.chat-header .block-button,
.chat-header .unblock-button {
  margin-left: auto; /* Push the button to the end of the header */
}

@media (max-width: 767px) {
  .chat-header {
    justify-content: center; /* Center the title on small screens */
  }

  .chat-header .block-button,
  .chat-header .unblock-button {
    position: static; /* Use static instead of absolute */
    transform: none; /* Remove any transforms */
    padding: 4px 8px;
    font-size: 0.75rem;
    margin-left: 8px; /* Ensure it doesn't stick to the title */
    order: 2; /* Place the button after the title */
  }

  .chat-header .MuiTypography-h6 {
    order: 1; /* Ensure the title comes first */
    font-size: 1rem;
    flex-grow: 1; /* Allow the title to grow and push the button to the edge */
    text-align: left; /* Align the title to the left */
  }
}
 
/* Ensure that the chat container itself has a width */
.chat-container {
  overflow-y: auto; /* Enables vertical scrolling */
  padding: 20px;
  height: 380px; /* Fixed height */
  max-height: 380px; /* Ensures the container does not exceed this height */
}
 
 .message {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
 
 .incoming {
  justify-content: flex-start; /* Align to left for incoming messages */
 }
 
 .avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 8px;
 }
 
 .message-area {
  display: flex;
  flex-direction: column;
  max-width: 100%; /* Allows the message area to expand up to the full width of its parent */
}

 .message-time {
  font-size: 0.75rem;
  color: #999;
  margin-bottom: 2px;
 }
 
/* Assuming .outgoing is added to the .message container for outgoing messages */
.outgoing {
  justify-content: flex-end; /* Align outgoing messages to the right */
}

.message-bubble {
  background-color: #dcf8c6; /* Light green for outgoing bubbles, change as necessary */
  border-radius: 18px;
  padding: 10px 12px;
  display: inline-block; /* Allows the bubble to grow with content */
  max-width: 60%; /* Maximum width of the text within the bubble before wrapping */
  word-wrap: break-word; /* Allows words to break and wrap to the next line */
  font-size: 14px; /* Fixed font size */
  margin-bottom: 6px; /* Space between messages */
  align-self: flex-end; /* Aligns the bubble to the right */
  text-align: left; /* Default text alignment for incoming messages */
}

/* Additional styles for incoming messages */

 .incoming .message-area {
  align-items: flex-start;
  align-self: flex-start; /* Align the message area to the start of the chat container */
 }
 
/* For incoming messages, if you want them on the left and with a different background color */
.incoming .message-bubble {
  background-color: #efefef; /* Light grey for incoming bubbles */
  align-self: flex-start; /* Aligns the bubble to the left */
}
 
 .outgoing .message-area {
  align-items: flex-end;
 }
 
 .outgoing .message-bubble {
  background-color: #0088cc; /* Instagram DM blue */
  color: white; /* Ensure text color is white */
  text-align: right; /* Align text to the right for outgoing messages */
 }
 
 .chat-input-area {
  display: flex;
  padding: 10px;
  border-top: 1px solid #dbdbdb;
 }
 
 .chat-input-field {
  margin-right: 8px;
  border: 1px solid #ccc;
  border-radius: 18px;
  padding: 8px 12px;
  flex-grow: 1;
 }
 
 .send-button {
  margin: auto 0;
 }
 
 .Button {
  background-color: #4CAF50;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 18px;
  cursor: pointer;
 }
 
 .Button:hover {
  background-color: #45a049;
 }

 .outgoing-text {
  color: #ffffff; /* This ensures text is white for outgoing messages */
}

.message-timestamp {
  text-align: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #8e8e8e;
  font-size: 0.75rem;
  display: flex;
  justify-content: center;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal-content {
  position: relative;
  padding: 20px;
  border-radius: 10px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal img {
  max-width: 90%;
  max-height: 80vh;
  object-fit: contain;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 15px;
  border: none;
  background: none;
  font-size: 1.5rem;
  cursor: pointer;
}

/* Adjusted base styles for image preview */
.image-preview-container img {
  max-width: 100%; /* Allows image to scale within its container */
  max-height: 150px; /* Aligns with the PostForm image preview height */
}

/* Responsive adjustments */
@media (max-width: 767px) {
  .image-preview-container img {
    max-width: 100%; /* Maintains full width within the container */
    max-height: 150px; /* Keeps height consistent, even on smaller screens */
  }
}

/* Base styles for image preview */
.image-preview {
  display: flex;
  justify-content: center; /* Center image in the preview area */
  align-items: center; /* Center image vertically */
  margin-bottom: 10px; /* Add some space below the preview */
}

.image-preview img {
  max-width: 100%; /* Limit the image width to its parent */
  max-height: 150px; /* Consistent with PostForm.js */
  object-fit: contain; /* Ensure the whole image is visible */
}

/* Responsive adjustments */
@media (max-width: 767px) {
  .image-preview img {
    max-height: 100px; /* Adjust size for smaller screens */
  }
}
