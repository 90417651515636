/* AdminDashboard.css */

.admin-dashboard-container {
  font-family: 'Roboto', sans-serif;
  margin: 20px;
  margin-left: 250px; /* Adjust based on your sidebar width */
  margin-top: 60px; /* Add or adjust this value to push content down */
  color: #333;
}
  
  .admin-dashboard-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .admin-dashboard-tabs {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap; /* Allow tabs to wrap to new line if needed */
    margin-bottom: 30px;
    border-bottom: 2px solid #e7e7e7;
    overflow-x: auto; /* Allows horizontal scrolling on small screens */
  }
  
  .admin-dashboard-tabs li {
    padding: 10px 15px;
    margin-right: 20px;
    cursor: pointer;
    border-bottom: 3px solid transparent;
    transition: all 0.3s ease;
    white-space: nowrap; /* Prevent text from wrapping */
  }
  
  .admin-dashboard-tab-button.active,
  .admin-dashboard-tabs li:hover {
    border-bottom: 3px solid #4267B2; /* Meta blue */
  }
  
  .admin-dashboard-content {
    background-color: #fff;
    border: 1px solid #e7e7e7;
    border-radius: 10px;
    padding: 20px;
    min-height: 300px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }  
  
  /* Post list styling */
  .admin-dashboard-content ul {
    list-style: none;
    padding: 0;
  }
  
  .admin-dashboard-content li {
    padding: 10px 0;
    border-bottom: 1px solid #e7e7e7;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .admin-dashboard-content li:last-child {
    border-bottom: none;
  }
  
  .admin-dashboard-content a {
    color: #4267B2;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .admin-dashboard-content a:hover {
    color: #2d4373;
  }
  
  /* Button styling */
  .button-delete {
    background-color: #f44336;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .button-delete:hover {
    background-color: #d32f2f;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .admin-dashboard-container {
      margin-left: 0;
    }
  }

/* Specific style for the last tab */
.admin-dashboard-tabs li:last-of-type {
    margin-right: 0;
  }

