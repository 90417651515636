/* UsersPosts.css */

.users-posts-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px 20px 20px;
    padding: 20px;
    padding-left: 240px; /* This ensures that on large screens the posts don't overlap with the sidebar */
}

/* Responsive adjustments for medium to large screens */
@media screen and (min-width: 768px) {
    .users-posts-container {
        margin-top: 40px;
        margin-left: 120px; /* Adjust this value so the posts don't shift too far to the left */
        margin-right: auto;
        max-width: 768px; /* Maintain the original max-width */
        padding-left: 40px; /* Add some padding to ensure content is not sticking to the sidebar */
    }
}

/* Further adjustments for very large screens */
@media screen and (min-width: 992px) {
    .users-posts-container {
        max-width: 900px;
        margin-right: auto;
        margin-left: 120px; /* Keep consistent with the 768px media query */
        padding-left: 40px; /* Maintain some padding on the left */
    }
}

/* Adjustments for very small screens */
@media screen and (max-width: 480px) {
    .users-posts-container {
        margin-top: 150px; /* Adjust this value to be equal to or greater than the Navbar's height */
        margin-bottom: 20px;
        margin-left: 5px;
        margin-right: 5px;
        padding: 10px;
    }
}
