/* Navbar.css */

#navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f4f7f9;
  font-family: 'Arial', sans-serif;
  color: #333;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 30px 10px; /* Increased top padding */
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  min-width: 320px;
}

#navbar .hamburger-menu {
  display: none;
  cursor: pointer;
}

#navbar .navbar__search {
  display: flex;
  align-items: center;
  position: relative;
  flex-grow: 1;
  margin-left: 10px;
}

#navbar .search-input {
  border: 1px solid #ccc;
  border-radius: 20px;
  padding: 5px 10px;
  font-size: 16px;
  flex-grow: 1;
  width: 100%;
}

#navbar .search-icons {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
}

#navbar .search-icon {
  color: #aaa;
  cursor: pointer;
}

#navbar .clear-search-icon {
  margin-right: 10px;
  color: #aaa;
  cursor: pointer;
}

#navbar .nav-icons {
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

#navbar .navbar-item {
  font-size: 1.5rem;
  margin: 0 5px; /* Reduced margin */
  color: #333;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 5px 10px; /* Reduced padding */
  border-radius: 20px;
}

#navbar .navbar-item:hover {
  color: #007bff;
}

#navbar .navbar-icon {
  margin-right: 5px;
  width: 20px; /* Reduced icon size */
  height: 20px; /* Reduced icon size */
}

#navbar .navbar-logo {
  margin-right: 5px;
  width: 30px; /* Reduced logo size */
  height: 30px; /* Reduced logo size */
}

#navbar .navbar-item .unread-messages-count {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: red;
  color: white;
  font-size: 0.75rem;
  padding: 0.25em 0.4em;
  border-radius: 50%;
  font-weight: bold;
  min-width: 1.5em;
  text-align: center;
}

@media (max-width: 768px) {
  #navbar {
    flex-wrap: wrap;
    padding: 30px 10px; /* Increased top padding */
  }

  #navbar .hamburger-menu {
    display: block;
    margin-right: 10px;
    padding: 5px;
  }

  #navbar .navbar__search {
    flex-basis: 70%;
    margin-left: 10px;
  }

  #navbar .nav-icons {
    order: 3;
    width: 100%;
    justify-content: center;
    margin-top: 10px;
  }

  #navbar .navbar-item {
    margin: 0 5px;
    font-size: 1.2rem;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  #navbar .hamburger-menu {
    display: block;
    cursor: pointer;
  }

  #navbar .navbar__search {
    margin-left: 10px;
    margin-right: auto;
    flex-grow: 1;
    max-width: 80%; /* Adjusted max-width */
  }

  #navbar .search-input {
    width: 100%;
  }
}

@media (min-width: 820px) and (max-width: 1180px) and (min-height: 1000px) and (max-height: 1370px) {
  #navbar .hamburger-menu {
    display: block;
    cursor: pointer;
  }

  #navbar .navbar__search {
    margin-left: 10px;
    margin-right: auto;
    flex-grow: 1;
    max-width: 70%;
  }

  #navbar .search-input {
    width: 100%;
  }
}

@media (min-width: 1024px) and (max-width: 1368px) {
  #navbar .hamburger-menu {
    display: block;
    cursor: pointer;
  }

  #navbar .navbar__search {
    margin-left: 10px;
    margin-right: auto;
    flex-grow: 1;
    max-width: 70%; /* Adjusted max-width for larger tablets */
  }

  #navbar .search-input {
    width: 100%;
  }
}

@media (min-width: 1368px) {
  #navbar .hamburger-menu {
    display: none;
  }

  #navbar {
    flex-direction: row;
    justify-content: space-between;
  }

  #navbar .navbar__search {
    margin-left: 250px;
    margin-right: auto;
    max-width: 600px;
  }

  #navbar .search-input {
    width: 100%;
  }
}

@media (max-width: 375px) {
  #navbar .navbar-item {
    padding: 5px 10px;
    font-size: 1.2rem;
  }

  #navbar .navbar-icon {
    width: 20px;
    height: 20px;
  }
}

/* iPad Air */
@media only screen 
  and (min-device-width: 820px) 
  and (max-device-width: 1180px) 
  and (-webkit-min-device-pixel-ratio: 1.5) {
    #navbar .navbar__search {
        margin-left: 200px; /* Adjusted left margin for iPad Air */
        max-width: 40%; /* Adjusted max-width for search field */
    }
    
    #navbar .navbar-item {
        font-size: 1rem; /* Adjusted font size for iPad Air */
        padding: 5px 10px; /* Adjusted padding for iPad Air */
    }
    
    #navbar .navbar-icon {
        width: 18px;
        height: 18px; /* Smaller icon size for iPad Air */
    }

    #navbar .navbar-logo {
        width: 30px;
        height: 30px; /* Smaller logo size for iPad Air */
    }
}  

/* iPad Pro */
@media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1366px)
  and (-webkit-min-device-pixel-ratio: 1.5) {
    #navbar .navbar__search {
        margin-left: 200px; /* Adjusted left margin for iPad Pro */
    }
    
    #navbar .navbar-item {
        font-size: 1.2rem; /* Adjusted font size for iPad Pro */
        padding: 5px 10px; /* Adjusted padding for iPad Pro */
    }
    
    #navbar .navbar-icon {
        width: 20px;
        height: 20px; /* Smaller icon size for iPad Pro */
    }
}

/* Surface Pro 7 */
@media only screen 
  and (min-device-width: 1368px)
  and (max-device-width: 1824px)
  and (-webkit-min-device-pixel-ratio: 1.5) {
    #navbar .navbar__search {
        margin-left: 250px; /* Adjusted left margin for Surface Pro 7 */
    }
    
    #navbar .navbar-item {
        font-size: 1.2rem; /* Adjusted font size for Surface Pro 7 */
        padding: 5px 10px; /* Adjusted padding for Surface Pro 7 */
    }
    
    #navbar .navbar-icon {
        width: 20px;
        height: 20px; /* Smaller icon size for Surface Pro 7 */
    }
}

/* Galaxy Z Fold 5 */
@media (min-width: 816px) and (max-width: 2208px) {
  #navbar .navbar-item {
    font-size: 0.9rem; /* Smaller font size for Galaxy Z Fold 5 */
    padding: 2px 4px; /* Smaller padding for Galaxy Z Fold 5 */
  }

  #navbar .navbar-icon {
    width: 14px; /* Smaller icon size for Galaxy Z Fold 5 */
    height: 14px; /* Smaller icon size for Galaxy Z Fold 5 */
  }
}

@media (min-width: 1264px) {
  #navbar .navbar-item {
    padding: 5px 10px;
    font-size: 1.1rem;
    border-radius: 12px;
  }

  #navbar .navbar__search {
    margin-left: 250px;
    margin-right: auto;
    max-width: 900px;
  }

  #navbar .navbar-icon {
    width: 12px;
    height: 12px;
  }
}

@media (min-width: 1300px) {
  #navbar .navbar-item {
    padding: 5px 10px; /* Reduced padding */
    font-size: 1.1rem; /* Reduced font size */
    border-radius: 12px;
  }

  #navbar .navbar__search {
    margin-left: 250px;
    margin-right: auto;
    max-width: 900px;
  }

  #navbar .navbar-icon {
    width: 12px; /* Reduced icon size */
    height: 12px; /* Reduced icon size */
  }
}