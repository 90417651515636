/* SuggestionGenerator.css */

.suggestion-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    margin-top: 80px;
}

.suggestion-form {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    margin: auto;
    max-width: 400px; /* Updated to match HomePage max-width */
}

.action-button {
    background: #1877F2;
    color: white;
    padding: 8px 16px;
    margin: 10px 0;
    border: none;
    outline: none;
    transition: background-color 0.2s, transform 0.1s;
    border-radius: 6px;
    box-shadow: none;
    font-weight: bold;
}

.action-button:hover, .action-button:focus {
    background: #165cd8;
    transform: translateY(-2px);
}

.action-button:active {
    background: #0a3c85;
    transform: translateY(1px);
}

.MuiCard-root {
    box-shadow: none;
    border-radius: 8px;
    margin: 0;
    padding: 20px;
    border: 1px solid #d3d3d3;
}

.MuiCardContent-root {
    padding: 0;
}

.MuiTextField-root {
    margin-bottom: 16px;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    background-color: #ffffff;
    width: 100%;
}

/* Align text inside dropdown fields to the left */
.MuiSelect-select {
    text-align: left;
    text-align-last: left;
}

.typography-h5 {
    font-size: 1.5rem;
    font-weight: 600;
}

.suggestions-list {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
}

.suggestion-link {
    text-align: left;
    display: block;
}

.suggestion-link, .image-link {
    display: inline;
    margin-right: 5px;
}

.image-link {
    text-decoration: none;
    white-space: nowrap;
    color: blue;
}

.submission-container {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    width: 100%;
    max-width: 600px;
    margin: 20px 0;
    text-align: left;
}

.submission-container img {
    width: auto;
    max-width: 100%;
    max-height: 600px;
    height: auto;
    object-fit: contain;
    margin: 0 auto;
}

.submission-container:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
    .suggestion-form-container {
        margin-left: 0;
    }

    .submission-container {
        max-width: 100%;
    }

    .submission-container img {
        max-height: 400px;
    }
}

.buttons-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    gap: 10px;
}

.buttons-container.two-buttons .action-button {
    margin: 0 2px;
}

@media (max-width: 480px) {
    .buttons-container {
        justify-content: center;
    }

    .action-button {
        max-width: 130px;
        margin: 0 10px;
    }
}

/* Interactive feedback styles */
.loading-indicator {
    display: flex;
    justify-content: center;
}

.success-message, .error-message {
    font-size: 0.875rem;
    margin-top: 10px;
    text-align: center;
}

/* Other possible overrides for Meta-like UI elements */
.MuiInputBase-root .MuiSelect-select {
    color: rgba(0, 0, 0, 0.87);
}

.MuiInput-underline:before {
    border-bottom: 2px solid rgba(0, 0, 0, 0.42);
}

/* Add this new rule */
.MuiMenuItem-root {
    color: rgba(0, 0, 0, 0.54);
}

/* Ensure dropdown arrow and clear icon are aligned properly */
.MuiInputBase-root .MuiSvgIcon-root {
    position: absolute;
    right: 12px;
}

/* Ensure dropdown arrow and clear icon are aligned properly */
.MuiInputBase-root .MuiSvgIcon-root {
    position: absolute;
    right: 12px;
}

/* Custom styles for aligning select text to left */
.MuiInputBase-root .MuiSelect-select {
    text-align: left;
    text-align-last: left;
}

/* Responsive adjustments for medium devices */
@media screen and (max-width: 992px) {
    .suggestion-form-container {
        margin-top: 120px;
        margin-left: 0;
    }
    .suggestion-form {
        max-width: calc(100% - 40px);
    }
}

/* Adjustments for smaller screens */
@media screen and (max-width: 768px) {
    .suggestion-form-container {
        margin-top: 120px;
    }
    .suggestion-form {
        max-width: calc(100% - 40px);
    }
}

/* Further adjustments for very small screens */
@media screen and (max-width: 576px) {
    .suggestion-form-container {
        margin-top: 120px;
    }
    .suggestion-form {
        max-width: calc(100% - 20px);
    }
}

/* Additional adjustments for larger screens */
@media screen and (min-width: 992px) {
    .suggestion-form-container {
        margin-left: calc((100vw - 1392px) / 2 + 120px);
    }
    .suggestion-form {
        max-width: calc(1392px - 300px);
    }
}

/* Custom styles for aligning select text to left */
.MuiInputBase-root .MuiSelect-select {
    text-align: left;
    text-align-last: left;
}

/* Ensure the dropdown arrow and clear icon remain aligned properly */
.MuiInputBase-root .MuiSvgIcon-root {
    position: absolute;
    right: 12px;
}

.suggestion-form-container, .suggestion-link, .MuiTextField-root, .MuiSelect-select {
    text-align: left;
}

/* Ensures all text within the component aligns to the left */
* {
    text-align: left !important;
}

/* Media Queries for specific devices */
@media only screen and (min-width: 834px) and (max-width: 1194px) {
    /* iPad Pro */
    .suggestion-form-container {
        margin-left: 250px; /* Add a left margin to avoid overlapping with the Sidebar */
    }
}

@media only screen and (min-width: 912px) and (max-width: 1368px) {
    /* Surface Pro 7 */
    .suggestion-form-container {
        margin-left: 250px; /* Add a left margin to avoid overlapping with the Sidebar */
    }
}

@media only screen and (min-width: 1024px) and (max-width: 600px) {
    /* Nest Hub */
    .suggestion-form-container {
        margin-left: 250px; /* Add a left margin to avoid overlapping with the Sidebar */
    }
}

/* Adjustments for screens with 1264px width */
@media (min-width: 1264px) and (max-width: 1264px) {
    .suggestion-form-container {
        margin-top: 140px; /* Move the SuggestionGenerator form slightly up */
    }
}
