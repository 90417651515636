/* MyPostsPage.css */

.my-posts-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px 20px 20px;
  padding: 20px;
  padding-left: 180px;
}

.my-posts-container h1 {
  margin-bottom: 40px; /* Adjust the value as needed */
}

@media screen and (max-width: 767px) {
  .my-posts-container {
    align-items: flex-start;
    margin: 140px 10px 10px;
    padding: 20px 10px;
    padding-left: 10px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .my-posts-container {
    margin-top: 140px;
    padding-left: 20px;
  }
}

@media screen and (min-width: 768px) and (max-width: 820px) {
  .my-posts-container {
    max-width: 600px;
    padding-left: 200px;
  }
  
  .my-posts-container .post {
    width: 100%;
    max-width: 500px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1366px) {
  .my-posts-container {
    transform: translateX(200px);
  }
  
  .my-posts-container .post {
    width: 100%;
    max-width: 700px;
  }
}

@media screen and (min-width: 1368px) {
  .my-posts-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: -150px;
    padding-left: 30px;
  }
  
  .my-posts-container.post {
    width: 100%;
    max-width: 700px;
  }
}

@media screen and (min-width: 1919px) {
  .my-posts-container {
    max-width: 600px;
    padding-left: 20px;
    padding-right: 20px;
  }
  
  .my-posts-container.post {
    width: 100%;
    max-width: 400px;
    padding: 10px;
  }
}

@media screen and (max-width: 1024px) and (min-width: 600px) {
  .my-posts-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
    padding: 20px;
  }
  
  .my-posts-container .post {
    width: 90%;
    max-width: 300px;
    padding: 10px;
  }
}

@media screen and (min-width: 1280px) and (max-width: 800px) {
  .my-posts-container {
    margin-top: 150px;
  }
   
  .my-posts-container.post {
    width: 100%;
    max-width: 550px;
  }
}

@media screen and (min-width: 768px) {
  .my-posts-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 768px;
  }
}

@media screen and (min-width: 992px) {
  .my-posts-container {
    max-width: 900px;
  }
}

@media screen and (min-width: 1300px) {
  .my-posts-container {
    margin-top: 100px;
    padding-left: 250px;
  }
}

/* Media queries for specific devices */

/* iPad Mini */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .my-posts-container {
    margin-top: 180px; /* Adjust the value to shift the posts down */
  }
}

/* iPad Air */
@media only screen and (min-device-width: 820px) and (max-device-width: 1180px) {
  .my-posts-container {
    margin-top: 200px; /* Adjust the value to shift the posts down */
  }
}

/* iPad Pro */
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) {
  .my-posts-container {
    margin-top: 220px; /* Adjust the value to shift the posts down */
  }
}

/* Surface Pro 7 */
@media only screen and (min-device-width: 912px) and (max-device-width: 1368px) {
  .my-posts-container {
    margin-top: 200px; /* Adjust the value to shift the posts down */
    padding-left: 250px; /* Adjust the value to shift the posts to the right */
  }
  
  .my-posts-container .post {
    width: 80%; /* Adjust the width of the posts */
    max-width: 600px; /* Adjust the maximum width of the posts */
    margin-left: auto; /* Align the posts to the right */
    margin-right: 0; /* Remove right margin */
  }
}

/* Asus Zenbook Fold */
@media only screen and (min-device-width: 1920px) and (max-device-width: 2560px) {
  .my-posts-container {
    margin-top: 250px; /* Adjust the value to shift the posts down */
  }
}

/* Nest Hub */
@media only screen and (min-device-width: 1024px) and (max-device-width: 600px) {
  .my-posts-container {
    margin-top: 160px; /* Adjust the value to shift the posts down */
  }
}

/* Nest Hub Max */
@media only screen and (min-device-width: 1280px) and (max-device-width: 800px) {
  .my-posts-container {
    margin-top: 180px; /* Adjust the value to shift the posts down */
  }
}

/* Add media query for 1264px screen width */
@media (min-width: 1264px) and (max-width: 1264px) {
  .my-posts-container {
    margin-left: -150px; /* Adjust this value as needed to shift the container to the left */
  }
}