/* Footer.css */

.app-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f4f7f9;
    color: #333;
    padding: 15px 0;
    width: 100%;
    text-align: center;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1000;
    font-family: 'Roboto', sans-serif;
  }
  
  .footer-icons {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }
  
  .footer-icon {
    color: #333;
    font-size: 28px;
    padding: 0 15px;
  }
  
  .footer-icon--new {
    font-size: 32px;
  }
  
  .footer-icon:hover {
    color: #007bff;
  }
  
  .footer-icon svg {
    font-size: 28px;
  }
  
  .footer-icon:not(:last-child):hover::after {
    content: '';
    display: block;
    margin: auto;
    height: 2px;
    width: 30px;
    background: #007bff;
    transition: width 0.3s;
  }
  
  /* Hide the footer when the sidebar is open on small screens */
  @media (max-width: 768px) {
    body.sidebar-open .app-footer {
      display: none;
    }
  }
  
  /* Optionally, if you want the footer to only show on small screens when the sidebar is not open: */
  @media (min-width: 769px) {
    .app-footer {
      display: none;
    }
  }
  
  @media (max-width: 768px) {
    .app-footer {
      display: flex;
    }
  }
  
