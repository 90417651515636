/* HomePage.css */

.posts {
  padding-top: 70px; /* Increased padding-top for appropriate spacing */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.post-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  padding: 0 15px;
}

.home-page-container {
  min-height: 100vh;
  position: relative;
  padding-bottom: 80px;
}

.home-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #f3f3f3;
  text-align: center;
  padding: 20px 0;
}

.footer-link {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

@media (max-width: 768px) {
  .posts {
    padding-top: 40px; /* Increased from 10px for more spacing */
    margin-left: 0;
    width: 100%;
  }

  .post-container {
    padding: 0 10px;
  }

  .home-footer {
    padding: 15px 0;
  }
}

@media (max-width: 480px) {
  .posts {
    padding-top: 40px; /* Increased from 10px for more spacing */
  }

  .home-footer {
    padding: 10px 0;
  }

  .footer-link {
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  .posts {
    width: calc(100% - 220px); /* Adjust width to shift posts slightly to the left */
    margin-left: 200px; /* Reduce margin-left to shift posts to the left */
    margin-top: 40px; /* Increased from 10px for more spacing */
  }
}

@media (max-width: 375px) {
  .posts {
    margin-top: 40px; /* Increased from 10px for more spacing */
  }
}

@media (max-width: 414px) {
  .posts {
    margin-top: 40px; /* Increased from 10px for more spacing */
  }
}

@media (max-width: 430px) {
  .posts {
    margin-top: 40px; /* Increased from 10px for more spacing */
  }
}

/* Add media query for Surface Duo */
@media (min-width: 540px) and (max-width: 720px) and (orientation: portrait), 
       (min-width: 720px) and (max-width: 1114px) and (orientation: landscape) {
  .posts {
    margin-top: 120px; /* Increased spacing for Surface Duo */
  }
}

/* Add media query for iPad Pro */
@media (min-width: 1024px) and (max-width: 1366px) and (orientation: portrait), 
       (min-width: 1366px) and (max-width: 1024px) and (orientation: landscape) {
  .posts {
    width: 90%; /* Increase the width for iPad Pro */
    margin-top: 70px; /* Add margin-top for more spacing */
  }

  .post-container {
    width: 100%;
    padding: 0 20px; /* Adjust padding as needed */
  }
}

/* Add media query for Surface Pro 7 */
@media (min-width: 912px) and (max-width: 1024px) {
  .posts {
    width: 90%; /* Increase the width for Surface Pro 7 */
    margin-top: 70px; /* Add margin-top for more spacing */
  }

  .post-container {
    width: 100%;
    padding: 0 20px; /* Adjust padding as needed */
  }
}

/* Add media query for iPad Mini */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait),
       only screen and (min-device-width: 1024px) and (max-device-width: 768px) and (orientation: landscape) {
  .posts {
    margin-top: 120px; /* Increased spacing for iPad Mini */
  }
}

/* Media queries for specified devices */

/* iPhone SE */
@media (max-width: 375px) and (max-height: 667px) {
  .posts {
    margin-top: 60px; /* Adjust the value as needed */
  }
}

/* iPhone XR */
@media (max-width: 414px) and (max-height: 896px) {
  .posts {
    margin-top: 60px; /* Adjust the value as needed */
  }
}

/* iPhone 12 Pro */
@media (max-width: 390px) and (max-height: 844px) {
  .posts {
    margin-top: 60px; /* Adjust the value as needed */
  }
}

/* iPhone 14 Pro Max */
@media (max-width: 430px) and (max-height: 932px) {
  .posts {
    margin-top: 60px; /* Adjust the value as needed */
  }
}

/* Pixel 7 */
@media (max-width: 393px) and (max-height: 851px) {
  .posts {
    margin-top: 60px; /* Adjust the value as needed */
  }
}

/* Samsung Galaxy S8+ */
@media (max-width: 360px) and (max-height: 740px) {
  .posts {
    margin-top: 60px; /* Adjust the value as needed */
  }
}

/* Samsung Galaxy S20 Ultra */
@media (max-width: 412px) and (max-height: 915px) {
  .posts {
    margin-top: 60px; /* Adjust the value as needed */
  }
}

/* Galaxy Z Fold 5 */
@media (max-width: 816px) and (max-height: 2208px) {
  .posts {
    margin-top: 60px; /* Adjust the value as needed */
  }
}

/* Samsung Galaxy A51/71 */
@media (max-width: 412px) and (max-height: 914px) {
  .posts {
    margin-top: 60px; /* Adjust the value as needed */
  }
}
