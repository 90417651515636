/* SignUpPage.css */

.signup-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 400px;
    margin: 120px auto; /* Match the top margin of LoginPage */
    padding: 30px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background-color: #ffffff;
    box-sizing: border-box;
}

.signup-header {
    font-size: 32px;
    margin-bottom: 30px;
    font-weight: bold;
}

.signup-input {
    width: 100%;
    padding: 15px;
    margin-bottom: 15px;
    font-size: 18px;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    background-color: #f8f8f8;
    transition: background-color 0.2s;
}

.signup-input:focus {
    background-color: #e8e8e8;
}

.signup-button {
    width: 100%;
    padding: 15px;
    font-size: 18px;
    background-color: #3897f0;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
}

.signup-button:hover {
    background-color: #307abf;
}

.signup-links {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.signup-text {
    font-size: 16px;
}

.signup-text a {
    color: #3897f0;
    text-decoration: none;
    margin-left: 5px;
}

.signup-text a:hover {
    text-decoration: underline;
}

.math-captcha {
    width: 100%;
    text-align: center; /* Ensure the MathCAPTCHA is centered */
    margin-bottom: 15px;
}

@media (min-width: 769px) {
    .signup-container {
        margin-left: 250px; /* Match the margin logic of LoginPage */
    }
}

@media (max-width: 768px) {
    .signup-container {
        margin: 150px auto;
        padding: 20px;
    }

    .signup-header {
        font-size: 24px;
    }

    .signup-input,
    .signup-button {
        font-size: 16px;
    }

    .math-captcha {
        font-size: 16px; /* Adjust the font size for smaller screens */
    }
}

@media (max-width: 480px) {
    .signup-container {
        margin: 180px auto;
        padding: 15px;
    }

    .signup-header {
        font-size: 24px;
    }

    .signup-input,
    .signup-button {
        font-size: 16px;
    }

    .math-captcha {
        font-size: 14px; /* Adjust the font size for smaller screens */
    }
}

/* Adjustments for iPad Air */
@media (min-width: 820px) and (max-width: 1024px) {
    .signup-container {
        margin-top: 150px; /* Further adjusted top margin for iPad Air */
    }
}

/* Adjustments for iPad Pro */
@media (min-width: 1024px) and (max-width: 1366px) {
    .signup-container {
        margin-top: 150px; /* Further adjusted top margin for iPad Pro */
        margin-left: auto;
        margin-right: 60px; /* Adjusted margin to move right */
    }
}

/* Adjustments for Surface Pro 7 */
@media (min-width: 1368px) and (max-width: 1368px) {
    .signup-container {
        margin-top: 150px;
        margin-left: 300px; /* Adjusted margin to move right and avoid the sidebar */
    }
}

/* Adjustments for Asus Zenbook Fold */
@media (min-width: 1920px) and (max-width: 1920px) {
    .signup-container {
        margin-top: 150px; /* Further adjusted top margin for Asus Zenbook Fold */
    }
}

/* Adjustments for Nest Hub */
@media (min-width: 1024px) and (max-width: 1280px) {
    .signup-container {
        margin-top: 150px; /* Further adjusted top margin for Nest Hub */
        margin-left: 250px; /* Adjusted margin to move right */
    }
}

/* Adjustments for Nest Hub Max */
@media (min-width: 1280px) and (max-width: 1280px) {
    .signup-container {
        margin-top: 150px; /* Further adjusted top margin for Nest Hub Max */
        margin-left: auto;
        margin-right: 60px; /* Adjusted margin to move right */
    }
}

/* Adjustments for large screens with viewport width of 1300px or higher */
@media (min-width: 1300px) {
    .signup-container {
        margin-top: 150px; /* Further adjusted top margin for large screens */
        margin-left: auto;
        margin-right: 60px; /* Adjusted margin to move right */
        max-width: 500px; /* Make the form wider for larger screens */
    }
}

/* Specific adjustments for iPad Mini */
@media (min-width: 768px) and (max-width: 834px) {
    .signup-container {
        margin-top: 180px; /* Adjust the top margin for iPad Mini */
    }
}

/* Specific adjustments for Surface Duo */
@media (min-width: 540px) and (max-width: 720px) {
    .signup-container {
        margin-top: 180px; /* Adjust the top margin for Surface Duo */
    }
}
